

'use strict';

window.onload = () => {

    /*
    Sumary
        1 - Products Destaques
        3 - Infinity Text
        4 - Set Border Right Products Card
        5 - Animation
    */


    /**************************** 1 - Products Destaques **********************/
    let variableScreen = 4
    if (window.screen.width < 1150) {
        variableScreen = 3
    } else if (window.screen.width < 900) {
        variableScreen = 2
    }


    const destaqueCardHeight = document.querySelectorAll("#home .section-destaque .content-products .destaque-card")
    let cardHeight = 0
    let lenghtCardDest = destaqueCardHeight.length
    if (lenghtCardDest > variableScreen) {
        lenghtCardDest = variableScreen
    }

    for (let i = 0; i < lenghtCardDest; i++) {
        if (destaqueCardHeight[i].clientHeight > cardHeight) {
            cardHeight = destaqueCardHeight[i].clientHeight
        }
    }

    const containerDestaque = document.querySelector("#home .section-destaque .content-products")
    containerDestaque.style.height = cardHeight + "px"


    function containerHeight(height) {
        TweenMax.to(containerDestaque, 1.5, {
            height: height,
        })
    }

    function openMoreDestaque() {
        if (countDestaque == 0) {
            containerHeight(`${cardHeight}px`)
            countDestaque++
            btnSeeMoreDestaques.innerHTML = 'Ver Mais'
        } else {
            containerHeight('auto')
            btnSeeMoreDestaques.innerHTML = 'Ver Menos'
            countDestaque--
        }
    }

    const btnSeeMoreDestaques = document.querySelector('.seeMoreDestaques')
    btnSeeMoreDestaques.addEventListener('click', openMoreDestaque)
    if (window.screen.width > 1199) {
        if (destaqueCardHeight.length <= 4)
            btnSeeMoreDestaques.style.display = 'none'
    }
    else if (window.screen.width < 1199 && window.screen.width > 899) {
        if (destaqueCardHeight.length <= 3)
            btnSeeMoreDestaques.style.display = 'none'

    } else if (window.screen.width < 899 && window.screen.width > 699) {
        if (destaqueCardHeight.length <= 2)
            btnSeeMoreDestaques.style.display = 'none'

    } else if (window.screen.width < 699 && window.screen.width > 599) {
        if (destaqueCardHeight.length <= 4)
            btnSeeMoreDestaques.style.display = 'none'

    } else if (window.screen.width < 599 && window.screen.width > 459) {
        if (destaqueCardHeight.length <= 3)
            btnSeeMoreDestaques.style.display = 'none'
    } else if (window.screen.width < 459) {
        if (destaqueCardHeight.length <= 2)
            btnSeeMoreDestaques.style.display = 'none'
    }

    let countDestaque = 1;






/********************************** 3 - Infinity Text ****************************/
const links = document.querySelectorAll(".js-text");
let marquee = document.querySelector('.text-single')

// assign the timeline returned from the helper function to 'loop'
let loop = horizontalLoop(links, {
    repeat: -1,
    speed: 1 + 0.5,
    draggable: false,
    reversed: false,
    paddingRight: parseFloat(gsap.getProperty(links[0], "marginRight", "px"))
});

// handle the timeline on hover
// marquee.addEventListener("mouseenter", () => {
//     loop.pause()
// })

// marquee.addEventListener("mouseleave", () => {
//     loop.play()
// })

// handle the timeline on scroll

let currentScroll = 0;
let scrollDirection = 1;

// window.addEventListener("scroll", () => {
//     let direction = window.pageYOffset > currentScroll ? 1 : -1;
//     if (direction !== scrollDirection) {
//         gsap.to(loop, { timeScale: direction, overwrite: true });
//         scrollDirection = direction;
//     }
//     currentScroll = window.pageYOffset;
// });




/*
This helper function makes a group of elements animate along the x-axis in a seamless, responsive loop.

Features:
 - Uses xPercent so that even if the widths change (like if the window gets resized), it should still work in most cases.
 - When each item animates to the left or right enough, it will loop back to the other side
 - Optionally pass in a config object with values like "speed" (default: 1, which travels at roughly 100 pixels per second), paused (boolean),  repeat, reversed, and paddingRight.
 - The returned timeline will have the following methods added to it:
   - next() - animates to the next element using a timeline.tweenTo() which it returns. You can pass in a vars object to control duration, easing, etc.
   - previous() - animates to the previous element using a timeline.tweenTo() which it returns. You can pass in a vars object to control duration, easing, etc.
   - toIndex() - pass in a zero-based index value of the element that it should animate to, and optionally pass in a vars object to control duration, easing, etc. Always goes in the shortest direction
   - current() - returns the current index (if an animation is in-progress, it reflects the final index)
   - times - an Array of the times on the timeline where each element hits the "starting" spot. There's also a label added accordingly, so "label1" is when the 2nd element reaches the start.
 */
function horizontalLoop(items, config) {
    items = gsap.utils.toArray(items);
    config = config || {};
    let tl = gsap.timeline({
        repeat: config.repeat,
        paused: config.paused,
        defaults: { ease: "none" },
        onReverseComplete: () =>
            tl.totalTime(tl.rawTime() + tl.duration() * 100)
    }),
        length = items.length,
        startX = items[0].offsetLeft,
        times = [],
        widths = [],
        xPercents = [],
        curIndex = 0,
        pixelsPerSecond = (config.speed || 1) * 100,
        snap =
            config.snap === false
                ? (v) => v
                : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
        populateWidths = () =>
            items.forEach((el, i) => {
                widths[i] = parseFloat(gsap.getProperty(el, "width", "px"));
                xPercents[i] = snap(
                    (parseFloat(gsap.getProperty(el, "x", "px")) / widths[i]) *
                    100 +
                    gsap.getProperty(el, "xPercent")
                );
            }),
        getTotalWidth = () =>
            items[length - 1].offsetLeft +
            (xPercents[length - 1] / 100) * widths[length - 1] -
            startX +
            items[length - 1].offsetWidth *
            gsap.getProperty(items[length - 1], "scaleX") +
            (parseFloat(config.paddingRight) || 0),
        totalWidth,
        curX,
        distanceToStart,
        distanceToLoop,
        item,
        i;
    populateWidths();
    gsap.set(items, {
        // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
        xPercent: (i) => xPercents[i]
    });
    gsap.set(items, { x: 0 });
    totalWidth = getTotalWidth();
    for (i = 0; i < length; i++) {
        item = items[i];
        curX = (xPercents[i] / 100) * widths[i];
        distanceToStart = item.offsetLeft + curX - startX;
        distanceToLoop =
            distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
        tl.to(
            item,
            {
                xPercent: snap(((curX - distanceToLoop) / widths[i]) * 100),
                duration: distanceToLoop / pixelsPerSecond
            },
            0
        )
            .fromTo(
                item,
                {
                    xPercent: snap(
                        ((curX - distanceToLoop + totalWidth) / widths[i]) * 100
                    )
                },
                {
                    xPercent: xPercents[i],
                    duration:
                        (curX - distanceToLoop + totalWidth - curX) /
                        pixelsPerSecond,
                    immediateRender: false
                },
                distanceToLoop / pixelsPerSecond
            )
            .add("label" + i, distanceToStart / pixelsPerSecond);
        times[i] = distanceToStart / pixelsPerSecond;
    }
    function toIndex(index, vars) {
        vars = vars || {};
        Math.abs(index - curIndex) > length / 2 &&
            (index += index > curIndex ? -length : length); // always go in the shortest direction
        let newIndex = gsap.utils.wrap(0, length, index),
            time = times[newIndex];
        if (time > tl.time() !== index > curIndex) {
            // if we're wrapping the timeline's playhead, make the proper adjustments
            vars.modifiers = { time: gsap.utils.wrap(0, tl.duration()) };
            time += tl.duration() * (index > curIndex ? 1 : -1);
        }
        curIndex = newIndex;
        vars.overwrite = true;
        return tl.tweenTo(time, vars);
    }
    tl.next = (vars) => toIndex(curIndex + 1, vars);
    tl.previous = (vars) => toIndex(curIndex - 1, vars);
    tl.current = () => curIndex;
    tl.toIndex = (index, vars) => toIndex(index, vars);
    tl.updateIndex = () =>
        (curIndex = Math.round(tl.progress() * (items.length - 1)));
    tl.times = times;
    tl.progress(1, true).progress(0, true); // pre-render for performance
    if (config.reversed) {
        tl.vars.onReverseComplete();
        tl.reverse();
    }
    if (config.draggable && typeof Draggable === "function") {
        let proxy = document.createElement("div"),
            wrap = gsap.utils.wrap(0, 1),
            ratio,
            startProgress,
            draggable,
            dragSnap,
            roundFactor,
            align = () =>
                tl.progress(
                    wrap(
                        startProgress + (draggable.startX - draggable.x) * ratio
                    )
                ),
            syncIndex = () => tl.updateIndex();
        typeof InertiaPlugin === "undefined" &&
            console.warn(
                "InertiaPlugin required for momentum-based scrolling and snapping. https://greensock.com/club"
            );
        draggable = Draggable.create(proxy, {
            trigger: items[0].parentNode,
            type: "x",
            // onPress() {
            //     startProgress = tl.progress();
            //     tl.progress(0);
            //     populateWidths();
            //     totalWidth = getTotalWidth();
            //     ratio = 1 / totalWidth;
            //     dragSnap = totalWidth / items.length;
            //     roundFactor = Math.pow(
            //         10,
            //         ((dragSnap + "").split(".")[1] || "").length
            //     );
            //     tl.progress(startProgress);
            // },
            // onDrag: align,
            // onThrowUpdate: align,
            inertia: true,
            snap: (value) => {
                let n =
                    Math.round(parseFloat(value) / dragSnap) *
                    dragSnap *
                    roundFactor;
                return (n - (n % 1)) / roundFactor;
            },
            onRelease: syncIndex,
            onThrowComplete: () => gsap.set(proxy, { x: 0 }) && syncIndex()
        })[0];
    }

    return tl;
}

var $tickerWrapper = $(".tickerwrapper");
var $list = $tickerWrapper.find("ul.list");
var $clonedList = $list.clone();
var listWidth = 100;

$list.find("li").each(function (i) {
    listWidth += $(this, i).outerWidth(true);
});

var endPos = $tickerWrapper.width() - listWidth;

$list.add($clonedList).css({
    "width": listWidth + "px"
});

$clonedList.addClass("cloned").appendTo($tickerWrapper);

//TimelineMax
var infinite = new TimelineMax({ repeat: -1, paused: true });
var time = 15;

infinite
    .fromTo($list, time, { rotation: 0.01, x: 0 }, { force3D: true, x: -listWidth, ease: Linear.easeNone }, 0)
    .fromTo($clonedList, time, { rotation: 0.01, x: listWidth }, { force3D: true, x: 0, ease: Linear.easeNone }, 0)
    .set($list, { force3D: true, rotation: 0.01, x: listWidth })
    .to($clonedList, time, { force3D: true, rotation: 0.01, x: -listWidth, ease: Linear.easeNone }, time)
    .to($list, time, { force3D: true, rotation: 0.01, x: 0, ease: Linear.easeNone }, time)
    .progress(1).progress(0)
    .play();

//Pause/Play


let numLoop = 4
if (window.screen.width < 999) {
    numLoop = 3
}


const containerList = document.querySelectorAll('.section-destaque')
containerList.forEach(e => {
    const cardList = e.querySelectorAll('.destaque-card')
    cardList.forEach((e, indice) => {
        if ((indice + 1) % numLoop == 0 || indice == cardList.length - 1) {
            e.style.borderRight = '0px solid gray'
        }
    })
})


var swiper = new Swiper(".mySwiper", {
    slidesPerView: 'auto',
    spaceBetween: 23,
    grabCursor: false,
    freeMode: true,
    loop: true,
    a11y: false,
    speed: 13000,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
});

/*************************** 5 - Animation ************************/
let windowAction = window
const toggle = "play none none none"
gsap.registerPlugin(ScrollTrigger);
TweenMax.from('#animation-home_banner', 1, {
    x: -100,
    delay: 1,
})
TweenMax.from('#carouselExampleControls', 1.5, {
    opacity: 0,
})
/*******************************/
gsap.from("#title-destaque", {
    scrollTrigger: {
        trigger: "#title-destaque",
        start: "top 85%",
        toggleActions: toggle,
    },
    y: -100,
    opacity: 0,
    duration: 1,
})
gsap.from(".content-products", {
    scrollTrigger: {
        trigger: ".content-products",
        start: "top 85%",
        toggleActions: toggle,
    },
    opacity: 0,
    duration: 1,
})
/*****************************************/
gsap.from("#title-banner", {
    scrollTrigger: {
        trigger: "#title-banner",
        start: "top 85%",
        toggleActions: toggle,
    },
    opacity: 0,
    y: -100,
    duration: 1,
})
gsap.from("#description-banner", {
    scrollTrigger: {
        trigger: "#description-banner",
        start: "top 85%",
        toggleActions: toggle,
    },
    opacity: 0,
    duration: 1,
})
/*****************************************/
gsap.from("#bussines-title", {
    scrollTrigger: {
        trigger: "#bussines-title",
        start: "top 85%",
        toggleActions: toggle,
    },
    opacity: 0,
    y: -100,
    duration: 1,
})
gsap.from("#bussines-subtittle", {
    scrollTrigger: {
        trigger: "#bussines-subtittle",
        start: "top 85%",
        toggleActions: toggle,
    },
    opacity: 0,
    duration: 1,
})
gsap.from("#bussines-description", {
    scrollTrigger: {
        trigger: "#bussines-description",
        start: "top 85%",
        toggleActions: toggle,
    },
    opacity: 0,
    stagger: 0.04,
    duration: 1,
})
gsap.from(".images-card", {
    scrollTrigger: {
        trigger: ".slider",
        start: "top 85%",
        toggleActions: toggle,
    },
    opacity: 0,
    y: 100,
    stagger: 0.05,
    duration: 1,
})
/*****************************************/

gsap.from("#news-title", {
    scrollTrigger: {
        trigger: "#news-title",
        start: "top 85%",
        toggleActions: toggle,
    },
    opacity: 0,
    y: -100,
    duration: 1,
})
gsap.from(".new-card", {
    scrollTrigger: {
        trigger: ".new-card",
        start: "top 85%",
        toggleActions: toggle,
    },
    opacity: 0,
    y: 100,
    duration: 1,
})
}
//////////////////////
